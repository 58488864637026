.date-wrapper {
  .MuiBadge-colorPrimary {
    background-color: red;
  }
  .MuiBadge-anchorOriginTopRightRectangle {
    transform: none;
  }
  .MuiBadge-badge {
    width: 12px;
    height: 12px;
    min-width: 0px;
    font-size: 0.55rem;
  }
}
.frontdesk-wrapper {
  text-align: -webkit-center;
  background: white;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 12px;
  .MuiAvatar-img {
    width: 32px;
    height: 32px;
  }
}

.occupancy-wrapper {
  .MuiCard-root {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
      0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  }
  .MuiCardHeader-root {
    padding: 10px;
    background-color: #faf2e8;
    .MuiTypography-h5 {
      font-size: 12px;
      color: #ff9800;
    }
  }
  .MuiCardContent-root {
    padding: 10px;
  }
  .MuiCardContent-root:last-child {
    padding-bottom: 10px;
  }
}

.revenue-wrapper {
  .MuiCard-root {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  }
  .MuiCardHeader-root {
    padding: 10px;
    background-color: #c7d6ff;
    .MuiTypography-h5 {
      font-size: 12px;
      color: #0019d8;
    }
  }
  .MuiCardContent-root {
    padding: 10px;
  }
  .MuiCardContent-root:last-child {
    padding-bottom: 10px;
    padding-right: 63px;
  }
}

.recharts-surface {
  overflow: inherit;
}

.card-form .MuiCardHeader-root {
  background-color: #faf2e8 !important;
}
